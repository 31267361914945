import { Item, MiscMarker } from '../classes';
import { IconFileNames } from './icons';
import { DefaultPOIData, MapIds } from './intel';
import { MarkerStore } from './types';

const markers = {
	wonderWeapon: new Item({
		title: `Wonder Weapon`, icon: IconFileNames.objective,
	}),
	wonderWeaponCollectible: new Item({
		title: `Wonder Weapon Collectible`, icon: IconFileNames.objective,
	}),
	wonderWeaponStep: new Item({
		title: `Wonder Weapon Step`, icon: IconFileNames.objective,
	}),
	// A required main quest step or interactable
	mainQuest: new Item({
		title: `Main Quest`, icon: IconFileNames.mainQuest,
	}),
	// Required for quests, usually visible on the leaderboard menu
	questCollectible: new Item({
		title: `Quest Collectible`, icon: IconFileNames.objective,
	}),
	bossFight: new Item({
		title: `Boss Fight`, desc: `A boss fight for this map, follow the main quest to begin.`, icon: IconFileNames.boss,
	}),
	clue: new Item({ title: `Visual Clue`, icon: IconFileNames.clue }),

	// An initially hidden area, or interactable, that makes available a new area that is not immediately accessible.
	secretArea: new Item({
		title: `Secret Area`, icon: IconFileNames.secretArea,
	}),
	// Can be interacted with, but provides no direct reward
	interactable: new Item({
		title: `Interactable Object`, icon: IconFileNames.interactable,
	}),
	// Die Maschine specific
	darkAetherPortal: new Item({
		title: `Dark Aether Portal`, icon: IconFileNames.orb, desc: `Possible spawn location.\nUsed to enter the Dark Aether.\nWill reopen after 25 kills if the objective associated with it is not completed.`,
	}),
	dieMaschinePaPPortal: new Item({
		title: `Dark Aether Portal`, icon: IconFileNames.orb, desc: `The first aether portal to spawn after turning on power, used to craft PaP.`,
	}),
	// D.I.E. Wonder Weapon Specific
	thermophasic: new Item({ title: `D.I.E. Thermophasic Upgrade`, icon: IconFileNames.upgrade, }),
	nova5: new Item({ title: `D.I.E. Nova-5 Upgrade`, icon: IconFileNames.upgrade, }),
	electrobolt: new Item({ title: `D.I.E. Electrobolt Upgrade`, icon: IconFileNames.upgrade, }),
	cryoemitter: new Item({ title: `D.I.E. Cryo-Emitter Upgrade`, icon: IconFileNames.upgrade, }),
	// Maeur Der Toten specific
	essenceHarvester: new Item({
		title: `Essence Harvester`, icon: IconFileNames.reactor,
	}),
	//Forsaken specific
	largeAetherCrystal: new Item({
		title: `Large Aether Crystal`, icon: IconFileNames.crystal,
	}),
	// Outbreak specific
	monkey: new Item({ title: 'Stone Monkey', desc: `Used in the first main quest in outbreak to obtain the microfilm only after completing the radio step on the previous map, otherwise just drops minor loot.`, icon: IconFileNames.monkey }),
	projector: new Item({ title: 'Projector', desc: 'The projector for the first main quest in outbreak.\nCan only be interacted with after obtaining the microfilm from a stone monkey statue.', icon: IconFileNames.projector, }), signal: new Item({ title: 'Signal' }),
	redRift: new Item({ title: 'Red Aether Rift', desc: 'Jump through all the rifts to continue the quest.', icon: IconFileNames.redRift, }),
	maxisQuestRadio: new Item({ title: 'Maxis Radio Signal', desc: 'Power on and then match the tone of the 3 surrounding amplifiers. Using subtitles is recommended.\nAfter activation will prompt you to call Maxis using a new menu option in the beacon.', icon: IconFileNames.mainQuest, }),
	maxisAmp: new Item({ title: 'Maxis Radio Amplifier', desc: 'Tune all of the 3 amplifiers to the same tone as the central radio in order to fix the radio and trigger the start of quest.', icon: IconFileNames.objective, }),
	bunny: new Item({ title: 'Mr Peeks', desc: 'Used in the quest, when picked up, provides a aetherium burst ability. Must be brought to the rover to continue the quest.', icon: IconFileNames.objective, }),
	// BO6 Specific
	pointOfPower: new Item({
		title: `Point Of Power Trap`, desc: `Cost: 1600\nUsed to boost your damage against and slow zombies.\nPart of the main quest, the symbol on the floor will light up with a different pattern after activating PaP.`, icon: IconFileNames.trap,
	}),
	bastardSword: new Item({
		title: `Bastard Sword`, desc: `Used as part of the main quest to unlock the incantations and the Mystic Orb.`, icon: IconFileNames.sword,
	}),
	bastardSwordUpgrade: new Item({
		title: `Bastard Sword Upgrade`, desc: `Upgrades the bastard sword to it's elemental variant.`, icon: IconFileNames.swordUpgrade,
	}),
	bastardSwordUpgradeCollectible: new Item({
		title: `Bastard Sword Collectible`, desc: `Used as part of the sword upgrade quest.`, icon: IconFileNames.objective,
	}),
	ritualCollectible: new Item({
		title: `Ritual Collectible`, icon: IconFileNames.objective,
	}),
	ritualStep: new Item({
		title: `Ritual Step`, icon: IconFileNames.objective,
	}),
	// Tomb
	crystalStep: new Item({
		title: `Dark Aether Pillar`, icon: IconFileNames.crystal,
		desc: `The pillar needs to be charged by completing a trial associated with the statue in front of it. Each statue will have a coloured crystal appear one after another, they can be initiated by shooting the crystal with the healing beam (alt fire) from the upgraded ice staff. The colour corresponds to the door where you can find the podium for the trial. The first one is blue/vermin.`
	}),
	aetherCrystalPodium: new Item({
		title: `Crystal Podium`, icon: IconFileNames.objective,
		desc: `This is the podium associated with the one in the dark aether. Each will have a statue of the special enemy associated with it. After completing the lockdown you need to take the energy from the podium back to the associated pillar in the aether. Try not to get hit.`
	}),
};

//TODO: Think about moving all main quest steps to an ordered list so we can enforce ordering and steps etc
const OutbreakEE2Steps = {
	step2Helicopter: new Item({ title: 'Crashed Helicopter', desc: `The transport chopper that the Omega Eight were using is located in the "Carved Hills", located south of the lone shack, having crashed by unknown means. Nearing it will spawn a horde needs to be eliminated, as one of the corpses is holding a message from Hugo Jager about where the surviving members of the crash went.`, icon: IconFileNames.mainQuest }),
	step3Orb: new Item({ title: 'Red Aetherial Orb', desc: 'The Aetherium Orb can spawn within three places and is visually distinct, having a darker hue of red and will not produce Essence upon being damaged. When damaged, it will flee like the standard variant for a total of three times before it will flee to hover over the Recon Rover to where it will stay above, unwilling to enter it.', icon: IconFileNames.redOrb, }),
};

// Used to generate IDs for all of the below misc markers:
// https://nanoid.jormaechea.com.ar/?alphabet=0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ_abcdefghijklmnopqrstuvwxyz&length=5
// Please use the existing settings included in the URL and check for duplicate ids when possible (it`s very very unlikely but still possible).

export const StaticQuestStore: MarkerStore = {
	[MapIds.dieMaschine]: [
		new MiscMarker(`w_xnt`, markers.thermophasic, [223.35366309316512, 223.1585943917737], { uniqueDesc: `Unlocked after following the steps with the fuse in the Dark Aether.`, linkedItems: `3eput` }),
		new MiscMarker(`9ySyg`, markers.nova5, [315.29654358848376, 295.69795800010473], { uniqueDesc: `Unlocked after following the steps with the cannister in the Dark Aether.`, linkedItems: `yLQtN` }),
		new MiscMarker(`XW3JZ`, markers.wonderWeapon, [174.74870507815817, 257.415635207116], { uniqueDesc: `Can be obtained after collecting the D.I.E remote and using it against the crack in the wall, activating the weapon and then hording enough zombies in front of the door to charge and shoot it.` }),
		new MiscMarker(`P7XG4`, markers.darkAetherPortal, [260.11042243500134, 282.13590175409416], { uniqueDesc: `If yours spawns here, the first aetherscope part will be the antenna under the stairs.` }),
		new MiscMarker(`HTstG`, markers.darkAetherPortal, [244.00586459413896, 75.84893284213877], { uniqueDesc: `If yours spawns here, the first aetherscope part will be on the crashed plane engine.` }),
		new MiscMarker(`wgKEY`, markers.questCollectible, [196.29722988085663, 307.495182630813], { uniqueTitle: `Aetherscope Part`, uniqueDesc: `1 of 3, One will spawn depending on the portal you used when entering the Dark Aether, dogs spawn after picking it up.` }),
		new MiscMarker(`N29aN`, markers.questCollectible, [356.6938648779643, 343.9768501463755], { uniqueTitle: `Aetherscope Part`, uniqueDesc: `1 of 3, One will spawn depending on the portal you used when entering the Dark Aether, dogs spawn after picking it up.` }),
		new MiscMarker(`yLQtN`, markers.questCollectible, [133.03685434894203, 282.6545483161539], { uniqueTitle: `Gas Cannister`, uniqueDesc: `Used in nova 5 steps, can only be retrieved with the alt fire of the D.I.E.` }),
		new MiscMarker(`3eput`, markers.questCollectible, [314.69833497058926, 344.089550203993], { uniqueTitle: `Fuse Box`, uniqueDesc: `Used in the Thermophasic upgrade steps, hidden under the plane nose, inaccessible unless in the Dark Aether.` }),
		new MiscMarker(`G7SE2`, markers.questCollectible, [242.97643511037063, 255.9881891661138], { uniqueTitle: `Vial Crate`, uniqueDesc: `Used in the Cryo upgrade steps. Shoot the crate with the D.I.E to knock it down.` }),
		new MiscMarker(`O0QxP`, markers.questCollectible, [256.63368476295705, 206.02147315077295], { uniqueTitle: `Tree Fungus`, uniqueDesc: `Used in the Cryo upgrade steps\nHave a Megaton shoot the fungus to activate it\nInteract whilst holding the vial to place it underneath the fungus and fill the vial.` }),
		new MiscMarker(`mlZBU`, markers.darkAetherPortal, [243.40377385527123, 207.31551716379568], { uniqueDesc: `Unlocked after completing the 3 ghostly dialogue steps.\nUsed specifically for the Thermophasic upgrade chest.` }),
		new MiscMarker(`nS4PT`, markers.questCollectible, [258.3778460127693, 335.23982709555946], { uniqueTitle: `Yellow Crystal`, uniqueDesc: `1 of 3 Crystals used in the Electrobolt upgrade steps, use the alt fire of the D.I.E to collect the energy, then run down to the upgrade box and shoot it to progress.` }),
		new MiscMarker(`Vc_0w`, markers.questCollectible, [377.14480548250435, 224.28934027831147], { uniqueTitle: `Yellow Crystal`, uniqueDesc: `1 of 3 Crystals used in the Electrobolt upgrade steps, use the alt fire of the D.I.E to collect the energy, then run down to the upgrade box and shoot it to progress.` }),
		new MiscMarker(`nhHde`, markers.questCollectible, [258.5949702531161, 173.9165165178388], { uniqueTitle: `Yellow Crystal`, uniqueDesc: `1 of 3 Crystals used in the Electrobolt upgrade steps, use the alt fire of the D.I.E to collect the energy, then run down to the upgrade box and shoot it to progress.` }),
		new MiscMarker(`pfJ2L`, markers.mainQuest, [160.42537161357052, 375.00631403381794], { uniqueTitle: `Broken Tank`, uniqueDesc: `Must be interacted with 3 times whilst holding the wrench\nShoot the zombie that pops out, then throw a lethal explosive into the now open hatch. This causes the gun to fire, dropping the Decontamination Agent from the tree it shoots.` }),
		new MiscMarker(`vm_wB`, markers.darkAetherPortal, [228.68530832965382, 267.7869413601128], { uniqueDesc: `Final portal, used to find the last ghostly dialogue before the boss fight.` }),
		new MiscMarker(`TbPJZ`, markers.mainQuest, [257.59332755993654, 349.50282422284494], { uniqueTitle: `Final Ghostly Dialogue`, uniqueDesc: `Final ghostly dialogue anomaly before the boss fight\nInteract with it and Orlov will talk about his family and place the family photo on the table.\nPicking up the photo when out of the dark aether will start the boss fight immediately.` }),
	],
	[MapIds.dieMaschineUnderground]: [
		new MiscMarker(`UTXXu`, markers.secretArea, [293.1716173546175, 370.9160154554998], { uniqueDesc: `Only accessible after using the Aether Tunnel above ground. 1 of 2 places the PaP part can spawn.` }),
		new MiscMarker(`6WnTv`, markers.secretArea, [206.2697306892794, 176.17280788708192], { uniqueDesc: `Only accessible after using the Aether Tunnel above ground. 1 of 2 places the PaP part can spawn.` }),
		new MiscMarker(`5M9A0`, markers.mainQuest, [211.50810943751497, 233.52599646325353], { uniqueTitle: `Dark Aether Laser Cutting`, uniqueDesc: `Requires the fuse and can only be interacted with when in the Dark Aether. Re-appears as open, above ground, on the back of the truck.` }),
		new MiscMarker(`5oG2A`, markers.interactable, [198.7906869627428, 178.82125886330365], { uniqueTitle: `Plaguehound Gas Condenser`, uniqueDesc: `Interact whilst holding the canister to place it, then kill a Plaguehound near it to fill it with gas.` }),
		new MiscMarker(`VpIJu`, markers.cryoemitter, [297.2665148522491, 349.81279151873895], { uniqueDesc: `Unlocked after pouring the liquid gathered from the fungus near the lake, onto the crate.`, linkedItems: `O0QxP` }),
		new MiscMarker(`VNWWl`, markers.electrobolt, [113.39539786187888, 353.078013760189], { uniqueDesc: `Unlocked after gathering the energy from 3 crystals and shooting it at the box. The bulbs around the box indicate the progress.`, linkedItems: `270nl` }),
		new MiscMarker(`EKc_L`, markers.wonderWeaponCollectible, [168.36179238711205, 199.31315219969227], { uniqueTitle: `D.I.E Remote`, uniqueDesc: `Inside a drawer that can be interacted with after finding the keycard, dropped by the first Megaton. Open to collect the D.I.E remote.`, linkedItems: `XW3JZ` }),
		new MiscMarker(`af5QE`, markers.questCollectible, [326.5807091917049, 308.47697244514126], { uniqueTitle: `Ghostly Dialog Orb`, uniqueDesc: `Next to speed cola, in the corner.\n1 of 3, will spawn in the Dark Aether after acquiring the Aetherscope. Interact with it to trigger the ghostly figure dialogue.` }),
		new MiscMarker(`xpxgz`, markers.questCollectible, [235.5479859749598, 273.1385305408606], { uniqueTitle: `Ghostly Dialog Orb`, uniqueDesc: `1 of 3, will spawn in the Dark Aether after acquiring the Aetherscope. Interact with it to trigger the ghostly figure dialogue.` }),
		new MiscMarker(`rj9BH`, markers.questCollectible, [181.93046504375246, 348.6110192429405], { uniqueTitle: `Ghostly Dialog Orb`, uniqueDesc: `1 of 3, will spawn in the Dark Aether after acquiring the Aetherscope. Interact with it to trigger the ghostly figure dialogue.` }),
		new MiscMarker(`Kgdjc`, markers.questCollectible, [215.23791437285269, 305.50159134986285], { uniqueTitle: `Aetherscope Part`, uniqueDesc: `1 of 3, one will spawn depending on the portal you used when entering the Dark Aether, dogs spawn after picking it up.` }),
		new MiscMarker(`JFz4Z`, markers.mainQuest, [339.1999615318702, 326.4211594207544], { uniqueTitle: `Vogel's Computer`, uniqueDesc: `After activating all 3 ghostly dialogues, interact with it 3 times to insert the password.` }),
		new MiscMarker(`LHGAv`, markers.dieMaschinePaPPortal, [172.09764607654128, 361.8028852234136], { uniqueDesc: `The first aether portal to spawn after turning on power, used to craft PaP.` }),
		new MiscMarker(`mR1Ye`, markers.darkAetherPortal, [273.33595868320293, 353.86231995501794], { uniqueDesc: `An aether portal that appears after shooting all 4 canisters of the containment device with the corresponding D.I.E upgrade.` }),
		new MiscMarker(`270nl`, markers.darkAetherPortal, [170.1085037631566, 387.66173529741445], { uniqueDesc: `Unlocked after completing the 3 ghostly dialogue steps.\nUsed specifically for the Electrobolt upgrade chest.` }),
		new MiscMarker(`mSMsk`, markers.darkAetherPortal, [121.83927074468262, 403.6735944951819], { uniqueDesc: `If yours spawns here, the first aetherscope part will be the one in the particle accelerator room.` }),
		new MiscMarker(`sHpo9`, markers.darkAetherPortal, [311.82894335858725, 299.6633680932173], { uniqueDesc: `Spawns after building the aetherscope. Used to get the diary and trigger the first of the ghostly dialogues.` }),
		new MiscMarker(`fwG9Z`, markers.mainQuest, [329.39656431710233, 325.66999226629395], { uniqueTitle: `Ghostly Dialogue`, uniqueDesc: `Appears after entering the portal that appears after shooting after shooting all 4 canisters of the containment device\nSpawns the Dark Aether Wrench.` }),
		new MiscMarker(`UyMmA`, markers.mainQuest, [310.66531882982684, 339.46395788705297], { uniqueTitle: `Containment Device`, uniqueDesc: `Up on the roof with four canisters pointing down. Shoot each one with the corresponding elemental version of the D.I.E\nAfter placing the decontamination agent, this will allow you to trap both halves of a Megaton. Furthering the main quest.` }),
		new MiscMarker(`rRnc1`, markers.bossFight, [188.234154595668, 343.69573623599854], { uniqueTitle: `Defend Orlov`, uniqueDesc: `Once you pick up the family photo you are teleported into the particle accelerator room and must defend Orlov whilst he shuts down the station.`, linkedItems: `TbPJZ` }),
	],
	[MapIds.firebaseZSpawn]: [
		new MiscMarker(`sdUX5`, markers.mainQuest, [382.9324256232408, 267.11524328413856], { uniqueTitle: `Sergei Ravenov`, uniqueDesc: `Must be interacted with to start the main quest.` }),
		new MiscMarker(`xkABv`, markers.questCollectible, [164.40213154389374, 204.72718267730167], { uniqueTitle: `Compound S16 - Truth Serum`, uniqueDesc: `1 of 3 compounds required as part of the truth serum in the main quest.` }),
		new MiscMarker(`sDGIO`, markers.wonderWeaponCollectible, [370.180211499256, 197.93612737161737], { uniqueTitle: `Dart Board / Aetherium Converter`, uniqueDesc: `Using a code found in the weapon lab PC, it can be shot in order (then the bullseye) to drop 1 of 3 parts used to create a free RAI K-84.` }),
		new MiscMarker(`yNwtb`, markers.bossFight, [219.4337277853481, 150.6913717044443], { uniqueDesc: `Orda Fight, activates after freeing Maxis from the OPC.` }),
	],
	[MapIds.firebaseZ]: [
		new MiscMarker(`Spi3p`, markers.wonderWeaponCollectible, [343.34375, 447.84375], { uniqueTitle: `Dimitri Kuhlklay`, uniqueDesc: `Burnt body on the floor that must be interacted with in order to gain access to the PC in the weapons lab, to progress the free RAI K-84 quest. (Note: the blueprint in the weapon lab must be picked up before you can interact with the body.)` }),
		new MiscMarker(`SWJb1`, markers.wonderWeapon, [158.77033215216278, 275.97284112275537], { uniqueTitle: `Kuhlklay's Desk`, uniqueDesc: `Used in the free RAI K-84 side quest. There is a PC, which must be accessed using Kuhlklay's eye, found on a body in scorched defense, against a tank\nOnce activated it will display graphics of a dart board used to obtain a RAI K-84 part back in the spawn area. A drawer will also open, allowing you to pick up a key, used to unlock lockers in the barracks.` }),
		new MiscMarker(`mwfRX`, markers.wonderWeaponCollectible, [271.687227595983, 315.28552532065964], { uniqueDesc: `Lockers that will spawn Mimics when opened with the key obtained from the weapons lab\nOne of the mimics will drop the Barrel Stabiliser, 1 of 3 parts used to create a free RAI K-84.` }),
		new MiscMarker(`50_41`, markers.wonderWeaponCollectible, [315.00241949127866, 323.7787002020902], { uniqueDesc: `Lockers that will spawn Mimics when opened with the key obtained from the weapons lab\nOne of the mimics will drop the Barrel Stabiliser, 1 of 3 parts used to create a free RAI K-84.` }),
		new MiscMarker(`vHAbq`, markers.wonderWeaponStep, [166.0783558425093, 265.6534721947788], { uniqueTitle: `Computer`, uniqueDesc: `Used as a charging station to charge up the Overcharged Power Cell that is 1 of the 3 parts in the RAI K-84 side quest. You may need to wait a couple of rounds after placing the magazine in order to fully charge it.` }),
		new MiscMarker(`4HGj2`, markers.mainQuest, [417.51780307656065, 318.2097920532709], { uniqueTitle: `Dr William Peck`, uniqueDesc: `Interact with after activating power across the map, then head back to Ravenov to acquire his ID card.` }),
		new MiscMarker(`dpWeL`, markers.questCollectible, [282.97038041254837, 215.9674819668458], { uniqueTitle: `Compound P65 - Truth Serum`, uniqueDesc: `1 of 3 compounds required as part of the truth serum in the main quest.` }),
		new MiscMarker(`gafr1`, markers.questCollectible, [388.66573427994837, 281.72880422271794], { uniqueTitle: `Compound 36 - Truth Serum`, uniqueDesc: `1 of 3 compounds required as part of the truth serum in the main quest.` }),
		new MiscMarker(`5PdCL`, markers.mainQuest, [284.4973798332315, 367.64687177418114], { uniqueTitle: `Chemical Mixer`, uniqueDesc: `Found in the field hospital, can be interacted with after obtaining all 3 compounds to drop the "Agent Delivery System" which will need to be placed on the air conditioning unit of Dr Peck's shelter.` }),
		new MiscMarker(`eu2st`, markers.mainQuest, [395.8771012671762, 317.7078936762941], { uniqueTitle: `Dr Peck's Air Conditioner`, uniqueDesc: `Used with the "Agent Delivery System" to deliver the truth serum to Dr Peck. Interact with him afterwards to progress the quest.` }),
		new MiscMarker(`_56GU`, markers.mainQuest, [170.33779197818103, 352.0203144700901], { uniqueTitle: `Memory Transference Station`, uniqueDesc: `Unlocked after drugging Dr Peck with truth serum. Provides essence traps that are used to catch mimics\nYou can bring the captured mimics back in order to get some dialog from different characters containing codes, the ones we need for the quest are Sokolov, Brahms and Zabim. Once all 3 codes are obtained, the machine will spit out a floppy disk.` }),
		new MiscMarker(`LX_RL`, markers.mainQuest, [401.70612515057087, 276.58454518563894], { uniqueTitle: `Quantum Main Frame`, uniqueDesc: `Can be interacted with after obtaining the floppy disk from the mimic step. Opens an anomaly in the large spherical chamber outside the window in front of you.` }),
		new MiscMarker(`q_skj`, markers.secretArea, [383.4063466479532, 299.8771916373098], { uniqueTitle: `OPC (Omega Portal Chamber)`, uniqueDesc: `Used as part of the main quest, after the mimic step, will open an anomaly and later after the satellite alignment step be must be interacted with in order to teleport to the boss fight.` }),
		new MiscMarker(`8YQJG`, markers.mainQuest, [408.23933867814446, 321.5496210454767], { uniqueTitle: `Aethermeter`, uniqueDesc: `Drops from a locker next to Dr Peck's shelter after unlocking the Quantum Main Frame and talking to Peck who provides the code.` }),
		new MiscMarker(`bWebw`, markers.questCollectible, [407.5156407861167, 391.44907135137566], { uniqueTitle: `Shovel`, uniqueDesc: `Required to dig up Containers after acquiring the Aethermeter.` }),
		new MiscMarker(`WkqxY`, markers.questCollectible, [240.21203547901894, 62.83786897191934], { uniqueTitle: `Aether Crystal 1 of 3`, uniqueDesc: `Can be dug up after acquiring the Shovel and Aethermeter. Triggers a lockdown event that will trap you in a yellow bubble, charging the crystal. Once complete, it can be picked up.` }),
		new MiscMarker(`N7CSE`, markers.questCollectible, [376.6346016707385, 261.46768955182176], { uniqueTitle: `Aether Crystal 1 of 3`, uniqueDesc: `Can be dug up after acquiring the Shovel and Aethermeter. Spawns lots of identical containers in the vicinity, to progress you need to pick up the container that ONLY has a crystal and WHITE smoke. Any black smoke inside a container indicates it is the wrong one.` }),
		new MiscMarker(`27K0v`, markers.questCollectible, [237.0421966518259, 369.7858267768484], { uniqueTitle: `Aether Crystal 1 of 3`, uniqueDesc: `Can be dug up after acquiring the Shovel and Aethermeter\nRequires the RAI K-84\nAfter being dug up, it will immediately teleport away from you, spawning back in a random location in the immediate vicinity. Once spotted, keep your distance and fire an alt fire grenade from the RAI K-84 at the container to keep it in place, then it can be collected.` }),
		new MiscMarker(`dMdFW`, markers.mainQuest, [389.48725462947607, 253.659943176447], { uniqueTitle: `Computer`, uniqueDesc: `Used after placing all 3 Crystals in the reactors. Will display a world map to realign a satellite, each will have a flag in the bottom right corner. When you see a location with a question mark, align with that one to activate the OPC and allow you to begin the boss fight.` }),
	],
	[MapIds.mauerDerToten]: [
		new MiscMarker(`2wAit`, markers.wonderWeaponStep, [384.32068891654967, 272.1759937481422], { uniqueTitle: `Blacklight Locker`, uniqueDesc: `Opened by using Klaus next to the locker door. Gives access to the blacklight.` }),
		new MiscMarker(`G107e`, markers.wonderWeaponCollectible, [181.79736917081456, 459.6975460903231], { uniqueTitle: `#2 Blacklight Numbers`, uniqueDesc: `needed for free CRBR-S side quest. Must be read using the blacklight acquired from the locker.`, linkedItems: `2wAit` }),
		new MiscMarker(`I_o9o`, markers.mainQuest, [341.84342386218685, 203.37097788580076], { uniqueTitle: `Klaus`, uniqueDesc: `Used at various points in the quest, must be activated with 2 parts, his hands, found in the hotel room and his battery, dropped by killing the first Krasny Soldat.\nAfter this he can be called in at various locations around the map. He can also be upgraded 2 times, once with a hacking helm to finish the main quest, and a second, optional time, to upgrade his firepower.` }),
		new MiscMarker(`h8fAY`, markers.questCollectible, [258.4350330544544, 328.8458598698313], { uniqueTitle: `Klaus Part - Electronic Chips`, uniqueDesc: `Box with green light, shoot with CRBR-S.` }),
		new MiscMarker(`GYRE6`, markers.interactable, [359.9483781264314, 284.16977220507744], { uniqueTitle: `Train Line Controls`, uniqueDesc: `Used to switch train tracks during the main quest.` }),
		new MiscMarker(`v9aLr`, markers.questCollectible, [168.93078742529096, 137.35493930841415], { uniqueTitle: `Essence Canister Pickup`, uniqueDesc: `Used to pick up empty canisters, can be dropped off in the same place when filled. (Wait a round for the forcefield to disappear)` }),
		new MiscMarker(`jOSoP`, markers.essenceHarvester, [151.62276338235262, 217.9029453522394], { uniqueDesc: `Possible spawn location of essence harvester.\nUsed in the quest to charge soul canisters.` }),
		new MiscMarker(`dgfYy`, markers.essenceHarvester, [258.13627287487003, 336.4144510859678], { uniqueDesc: `Possible spawn location of essence harvester.\nUsed in the quest to charge soul canisters.` }),
		new MiscMarker(`CWAYy`, markers.secretArea, [310.84582665268846, 317.22578660679153], { uniqueTitle: `Train Carriage`, uniqueDesc: `Use Klaus to stop the train, contains the Keycard and Warhead needed for the main quest.` }),
		new MiscMarker(`4QpYx`, markers.secretArea, [168.43591618416733, 160.56583724877993], { uniqueTitle: `Valentina's Lab`, uniqueDesc: `Klaus can knock the wall down. Then use the CRBR-S beam mod to open the metal door.` }),
		new MiscMarker(`xLLmm`, markers.mainQuest, [153.92452665732722, 149.36476379238186], { uniqueTitle: `Endstation Lure Machine`, uniqueDesc: `When shot, after placing the essence canisters, will provide endstation lures which are used at the essence charging stations to spawn tempests. When killed, the tempests charge the canister placed in the station.` }),
		new MiscMarker(`HxZ4L`, markers.mainQuest, [349.7773651391249, 186.89792418690953], { uniqueTitle: `Satellite Computer`, uniqueDesc: `1 of 3 Computers used during main quest to spawn Megatons, requires keycard from the train. Player will be denied if they have not yet upgraded Klaus.` }),
		new MiscMarker(`6Z0wX`, markers.bossFight, [168.0886289452726, 81.37624099731451], { uniqueDesc: `Bossfight initial spawn location.` }),
		new MiscMarker(`BcQMe`, markers.mainQuest, [167.82182159774044, 39.754294782294636], { uniqueTitle: `Klaus Escort`, uniqueDesc: `Klaus will still need to be escorted whilst carrying the warhead in order to successfully finish the bossfight.` }),
	],
	[MapIds.mauerDerTotenStreets]: [
		new MiscMarker(`PwmRS`, markers.wonderWeaponCollectible, [110.80096229476811, 298.4347086625506], { uniqueTitle: `#1 Blacklight Numbers`, uniqueDesc: `Needed for free CRBR-S side quest. Must be read using the blacklight acquired from the locker.`, linkedItems: `2wAit` }),
		new MiscMarker(`muHxa`, markers.wonderWeaponCollectible, [302.33874571909564, 311.0221039512368], { uniqueTitle: `#3 Blacklight Numbers`, uniqueDesc: `Needed for free CRBR-S side quest. Must be read using the blacklight acquired from the locker.`, linkedItems: `2wAit` }),
		new MiscMarker(`msude`, markers.wonderWeapon, [110.16024664719373, 451.7354570039043], { uniqueTitle: `Free CRBR-S`, uniqueDesc: `Open the safe by finding the 3 numbers around the map with the blacklight.`, linkedItems: `2wAit` }),
		new MiscMarker(`0ZFbr`, markers.secretArea, [118.31471157271051, 467.80515920170296], { uniqueTitle: `Hotel Room 305 & Klaus Part`, uniqueDesc: `Use a brain rot zombie to open door and get Klaus hands and the safe for CRBR-S.` }),
		new MiscMarker(`p3Ots`, markers.questCollectible, [267.02354016528875, 171.06511860475285], { uniqueTitle: `Klaus Part - Battery`, uniqueDesc: `Obtained from killing the first Krasny Soldat.` }),
		new MiscMarker(`eOTNC`, markers.questCollectible, [373.50680516628154, 388.36535534693917], { uniqueTitle: `Klaus Part - Transistor`, uniqueDesc: `Shoot radios in the room with CRBR-S until one of them drops the part.` }),
		new MiscMarker(`DwMIE`, markers.questCollectible, [333.76018343302235, 467.30140318168293], { uniqueTitle: `Klaus Part - Antenna`, uniqueDesc: `Shoot pylon with CRBR-S.` }),
		new MiscMarker(`5pm__`, markers.questCollectible, [197.11229814842187, 196.7896920958058], { uniqueTitle: `Klaus Part - Microwave dish`, uniqueDesc: `Dig piles of debris to find.` }),
		new MiscMarker(`3S1gY`, markers.questCollectible, [301.6366591960302, 150.97963262432313], { uniqueTitle: `Klaus Part - Microwave dish`, uniqueDesc: `Dig piles of debris to find.` }),
		new MiscMarker(`WzV96`, markers.mainQuest, [144.5504050965535, 289.50012070442347], { uniqueTitle: `Klaus Upgrade Station` }),
		new MiscMarker(`aqK7n`, markers.mainQuest, [290.44881000922993, 44.59962748588934], { uniqueTitle: `Satellite Computer`, uniqueDesc: `1 of 3 Computers used during main quest to spawn Megatons, requires keycard from the train. Player will be denied if they have not yet upgraded Klaus.` }),
		new MiscMarker(`zq5i8`, markers.mainQuest, [377.5561578030767, 339.7506667046973], { uniqueTitle: `Satellite Computer`, uniqueDesc: `1 of 3 Computers used during main quest to spawn Megatons, requires keycard from the train. Player will be denied if they have not yet upgraded Klaus.` }),
		new MiscMarker(`YqN75`, markers.essenceHarvester, [181.1322876506002, 304.5979694369277], { uniqueDesc: `Possible spawn location of essence harvester.\nUsed in the quest to charge soul canisters.` }),
		new MiscMarker(`vgkPi`, markers.essenceHarvester, [173.4707721095559, 358.04367363412337], { uniqueDesc: `Possible spawn location of essence harvester.\nUsed in the quest to charge soul canisters.` }),
		new MiscMarker(`frfHo`, markers.essenceHarvester, [357.6295162672377, 389.7021367473598], { uniqueDesc: `Possible spawn location of essence harvester.\nUsed in the quest to charge soul canisters.` }),
		new MiscMarker(`BWQZ0`, markers.essenceHarvester, [365.3697268917473, 244.2640308995389], { uniqueDesc: `Possible spawn location of essence harvester.\nUnder the roof in the destroyed penthouse. Used in the quest to charge soul canisters.` }),
		new MiscMarker(`rsNWp`, markers.essenceHarvester, [276.8902900069504, 110.18445130029662], { uniqueDesc: `Possible spawn location of essence harvester.\nUsed in the quest to charge soul canisters.` }),
		new MiscMarker(`suL35`, markers.mainQuest, [199.8542173319167, 303.14278836890117], { uniqueTitle: `Uranium Step`, uniqueDesc: `Zipline used to place one of the uranium devices.` }),
		new MiscMarker(`0vSdg`, markers.mainQuest, [292.27782488970274, 292.907055944363], { uniqueTitle: `Uranium Step`, uniqueDesc: `Zipline used to place one of the uranium devices.` }),
		new MiscMarker(`guuWl`, markers.mainQuest, [251.03046990519945, 172.27978216881027], { uniqueTitle: `HVT Spawn`, uniqueDesc: `Spawn location for the first HVT Megaton which drops the 2 pieces of uranium needed to craft the uranium devices. Can be taken to any workbench to craft.` }),
		new MiscMarker(`pOuZZ`, markers.mainQuest, [327.83864123291437, 440.6007233273124], { uniqueTitle: `HVT Spawn`, uniqueDesc: `Spawn location for the second HVT Megaton which drops the 2 pieces of uranium needed to craft the uranium devices. Can be taken to any workbench to craft.` }),
		new MiscMarker(`yg8rq`, markers.mainQuest, [244.90656540916956, 296.09692296650684], { uniqueTitle: `Cleansed Uranium`, uniqueDesc: `Used in the main quest to arm the warhead placed in the lab. 2 are required and they both require the same steps, with different Megaton spawn locations.` }),
	],
	[MapIds.forsaken]: [
		new MiscMarker(`Ja2jc`, markers.wonderWeaponCollectible, [423.88068477781434, 127.8243562109435], { uniqueTitle: `Polymorphic Crystal Core`, uniqueDesc: `Will spawn after pressing the shutter button, after round 8-9. When interacted with it will spawn a large wave of zombies, fend them off to allow it to be picked up.\nUsed to build the wonder weapon quest, also counts as an intel item.` }),
		new MiscMarker(`XFd3U`, markers.wonderWeaponCollectible, [383.1791506219179, 177.57780040015749], { uniqueTitle: `Tempered Crystal Heart`, uniqueDesc: `Dropped by a tormentor, only when killed using a flamethrower.\nUsed to build the wonder weapon quest, also counts as an intel item.` }),
		new MiscMarker(`VMpbM`, markers.mainQuest, [105.26703279268563, 77.52976775995383], { uniqueTitle: `Activision Grand Prix`, uniqueDesc: `Cost: 2000 Essence\nActivated after killing a zombie with deadwire next to it. You will need to drive the ARC-XD behind the loose ventilation shaft in the TV store.` }),
		new MiscMarker(`1QoYt`, markers.questCollectible, [76.34043931284971, 93.08541305858377], { uniqueTitle: `Circuit Board`, uniqueDesc: `Found somewhere on the side in the backroom between the video store and the arcade. Used to repair the teleporter and get to the basement.` }),
		new MiscMarker(`aWlqt`, markers.questCollectible, [171.2979612374162, 151.22479395215734], { uniqueTitle: `Focusing Lens`, uniqueDesc: `Found in the cinema entrance. Used to repair the teleporter and get to the basement.` }),
		new MiscMarker(`rhCLH`, markers.questCollectible, [118.87891298990084, 175.87422735426276], { uniqueTitle: `Cathode-Ray Tube`, uniqueDesc: `Found in the TV Store. Used to repair the teleporter and get to the basement.` }),
		new MiscMarker(`veYA5`, markers.questCollectible, [179.37100229248503, 228.78204497293126], { uniqueTitle: `Fryer Cage`, uniqueDesc: `Found in Burger Town. Used to repair the teleporter and get to the basement.` }),
		new MiscMarker(`pKjFl`, markers.questCollectible, [245.7535763129745, 264.45501216733527], { uniqueTitle: `Broken Teleporter`, uniqueDesc: `Must be repaired in order to get to the basement and enable power.` }),
		new MiscMarker(`ae_ve`, markers.mainQuest, [236.66438512209962, 331.39708807762185], { uniqueTitle: `Button For Shutters`, uniqueDesc: `Press to begin the easter egg and enter the first cutscene.` }),
		new MiscMarker(`Jvfzd`, markers.questCollectible, [221.49957076603778, 264.8502010445337], { uniqueTitle: `Fuel Tank`, uniqueDesc: `Obtained by interacting with one of the large white fuel tanks and completing the lockdown.` }),
		new MiscMarker(`RV0mf`, markers.questCollectible, [113.2661885050118, 164.47601941465607], { uniqueTitle: `Monitoring Device`, uniqueDesc: `Drops from a hole in the wall in the TV Store after using the Activision Grand Prix arcade machine.` }),
		new MiscMarker(`ldDz3`, markers.largeAetherCrystal, [271.9707732304297, 91.92974890168614], { uniqueTitle: `Large Dark Aether Crystal #2`, uniqueDesc: `Spawns 3 floating orbs that must be shot before the main crystal can be destroyed with the Crysalax Savager.\nThis will drop a Crystal Shard. These must be thrown in the mouth of an abomination, turning it's tail orange. Then it must be killed to obtain a Catalyzed Crystal Shard. Do this for all 3 crystals around the map.` }),
		new MiscMarker(`MUexS`, markers.largeAetherCrystal, [350.61973067962373, 191.0702416911157], { uniqueTitle: `Large Dark Aether Crystal #3`, uniqueDesc: `Spawns 3 floating orbs that must be shot before the main crystal can be destroyed with the Crysalax Savager.\nThis will drop a Crystal Shard. These must be thrown in the mouth of an abomination, turning it's tail orange. Then it must be killed to obtain a Catalyzed Crystal Shard. Do this for all 3 crystals around the map.` }),
		new MiscMarker(`JCpmh`, markers.mainQuest, [383.32564714876884, 269.6057051613007], { uniqueTitle: `Aetherium Neutralizer`, uniqueDesc: `Used to trigger the start of the boss battle. Once constructed will prompt you to Activate it. When activated it will start an escort mission through the tunnel ahead. Collect orange crystals along the way to fuel the Neutralizer. Once you reach the end power up stations will spawn, before you begin the boss fight. ` }),
		new MiscMarker(`8JYiv`, markers.bossFight, [107.80007127817684, 410.8341478679704], { uniqueTitle: `The Forsaken`, uniqueDesc: `Boss battle spawn location.` }),
	],
	[MapIds.forsakenUnderground]: [
		new MiscMarker(`BhVQV`, markers.mainQuest, [296.8934702338299, 46.20376039078092], { uniqueTitle: `Abomination Spawn`, uniqueDesc: `Will spawn upon the first time approaching the portal, must be defeated to lift lockdown and enable power.` }),
		new MiscMarker(`rN5Pg`, markers.questCollectible, [220.32935092146133, 373.6915310551705], { uniqueTitle: `Housing Unit`, uniqueDesc: `Drops from the wall when charged at by an abomination. Used as part of constructing the Aetherium Neutralizer` }),
		new MiscMarker(`mVUTb`, markers.wonderWeaponCollectible, [186.7283472806809, 448.0908425556309], { uniqueTitle: `Energetic Geode`, uniqueDesc: `Can be dropped from any dark aether crystal, only when it is destroyed by the electrical beam of an abomination.\nUsed to build the wonder weapon quest, also counts as an intel item.` }),
		new MiscMarker(`BjO0P`, markers.wonderWeapon, [407.98348792558136, 223.89943180145679], { uniqueTitle: `Chrysalax Savager`, uniqueDesc: `Is crafted inside the water tank using the 3 required components, after placing the items you must get melee kills near the tank in order to charge/craft the weapon.` }),
		new MiscMarker(`mVIvK`, markers.largeAetherCrystal, [296.373367239989, 339.2622775270375], { uniqueTitle: `Large Dark Aether Crystal #1`, uniqueDesc: `Spawns 3 floating orbs that must be shot before the main crystal can be destroyed with the Crysalax Savager.\nThis will drop a Crystal Shard. These must be thrown in the mouth of an abomination, turning it's tail orange. Then it must be killed to obtain a Catalyzed Crystal Shard. Do this for all 3 crystals around the map.` }),
	],
	/////////////////////Outbreak Items/////////////////////////
	[MapIds.zoo]: [
		new MiscMarker(`G6f1o`, markers.monkey, [357.6836499712147, 349.54995004995004], { uniqueDesc: `Midway up the stairs in the book nook.` }),
		new MiscMarker(`dkJOS`, markers.monkey, [334.6704087507196, 262.5942934616404], { uniqueDesc: `In the corner on a green cabinet.` }),
		new MiscMarker(`AqCfQ`, markers.monkey, [204.52540299366723, 279.51968694570735], { uniqueDesc: `Between a wall and a rock.` }),
		new MiscMarker(`t1Csu`, markers.monkey, [266.54015544041454, 117.57161206140799], { uniqueDesc: `In the middle of the large shelves.` }),
		new MiscMarker(`4uYKW`, markers.maxisQuestRadio, [191.73889214493497, 311.0023178810189]),
		new MiscMarker(`EJH8M`, markers.maxisAmp, [207.95152974316207, 301.11136617864145], { uniqueDesc: `Behind the counter.` }),
		new MiscMarker(`dJZK6`, markers.maxisAmp, [200.05547677172723, 325.69574229352554]),
		new MiscMarker(`zNOoG`, markers.maxisAmp, [188.1875, 328.0625], { uniqueDesc: `Up the stairs, on the concrete bags.` }),
		new MiscMarker(`a84zo`, markers.projector, [241.65018710420264, 295.65660105200925]),
		new MiscMarker(`QDny7`, markers.redRift, [194.5625, 399.875]),
	],
	[MapIds.duga]: [
		new MiscMarker(`uReU5`, markers.monkey, [182.73715299184204, 242], { uniqueDesc: `On top of the small building` }),
		new MiscMarker(`knmox`, markers.monkey, [339.4971340539545, 215.75], { uniqueDesc: `On top of a stack of barrels.` }),
		new MiscMarker(`FIUz1`, markers.monkey, [358.4786674447592, 360.5], { uniqueDesc: `At the back on top of the bus stop.` }),
		new MiscMarker(`P79of`, markers.monkey, [107.24119968079447, 330], { uniqueDesc: `Behind the wall` }),
		new MiscMarker(`RGgyM`, markers.maxisQuestRadio, [329.4916390857576, 444.7086117644694]),
		new MiscMarker(`vwHf6`, markers.maxisAmp, [338.5566004481648, 448.38714681008395]),
		new MiscMarker(`x5PtX`, markers.maxisAmp, [310.7048351027977, 432.2278678597059]),
		new MiscMarker(`inuqE`, markers.maxisAmp, [339.3448579579393, 424.73942151684776]),
		new MiscMarker(`d2US7`, markers.projector, [173.2474009132825, 354.5625]),
		new MiscMarker(`ORM0f`, markers.redRift, [391.40929445880636, 252.02191837378084], { uniqueDesc: `Underground, in the bunker.` }),
	],
	[MapIds.ruka]: [
		new MiscMarker(`X6iHS`, markers.monkey, [126.02392962817726, 148], { uniqueDesc: `On the third shelf next to three boxed.` }),
		new MiscMarker(`NXZCq`, markers.monkey, [312.61036148443907, 345], { uniqueDesc: `Behind some pallets next to the train platform.` }),
		new MiscMarker(`FqjzW`, markers.monkey, [366.728066736379, 131], { uniqueDesc: `Leaning against the broken down car.` }),
		new MiscMarker(`SGa0I`, markers.monkey, [238.30978121120768, 448.3125], { uniqueDesc: `At the end of the railroad, under rafters.` }),
		new MiscMarker(`BC05H`, markers.maxisQuestRadio, [216.7660299006794, 90.02961020357569]),
		new MiscMarker(`GvD6O`, markers.maxisAmp, [242.79564351743252, 88.82921540086134]),
		new MiscMarker(`2ebQM`, markers.maxisAmp, [229.78083670905596, 106.70878009392233]),
		new MiscMarker(`IBUd1`, markers.maxisAmp, [194.6534940612047, 89.46100213913205]),
		new MiscMarker(`H953x`, markers.secretArea, [166.0827125211619, 188.495466417831], { uniqueDesc: `Elevator to the underground silo as part of the first main outbreak quest. Unlocks after completing the monkey microfilm and projector step and teleporting to Ruka on or after round 5.` }),
		new MiscMarker(`NuoPB`, markers.mainQuest, [162.21311576252214, 199.5317561060424], { uniqueDesc: `Legion Boss Fight\nCan only be started after accessing the missile silo, collecting 3 keys and triggering the launch sequence in the right order.` }),
		new MiscMarker(`qRTu_`, markers.mainQuest, [186.37934131448654, 184.56152788801134], { uniqueTitle: `Silo A`, uniqueDesc: `Approximate Location\nContains the essence trap used to capture the monkey for one of the ICBM keys. Also has a launch control board, the green lights indicate the order of activation after acquiring the keys.` }),
		new MiscMarker(`grcbU`, markers.mainQuest, [172.69227551514388, 219.84849440194168], { uniqueTitle: `Silo B`, uniqueDesc: `Approximate Location\nContains the red aetherium canister, can be charged by shooting and collecting crystal shard and depositing them. After charging, will need to be taken to Silo D. Also has a launch control board, the green lights indicate the order of activation after acquiring the keys.` }),
		new MiscMarker(`2GHxO`, markers.mainQuest, [137.72609960588565, 209.4762648508773], { uniqueDesc: `Silo D\nApproximate Location\n Contains the jellyfish that holds a key, only accessible whilst using the charged aetherium canister from Silo B, remember to grab the key whilst being dragged through the jellyfish. Also has a launch control board, the green lights indicate the order of activation after acquiring the keys.` }),
		new MiscMarker(`Fhzza`, markers.mainQuest, [152.80325802547407, 191.6189211907974], { uniqueDesc: `Central Silo Room\nApproximate Location\nSpawn location of the red mimic that must be killed to drop one of the 3 ICBM keys.` }),
		new MiscMarker(`KDAvD`, markers.redRift, [244.73564018442983, 88.5]),
	],
	[MapIds.alpine]: [
		new MiscMarker(`EXt18`, markers.monkey, [256.4818629189573, 69.25], { uniqueDesc: `On the balcony against the wall.` }),
		new MiscMarker(`VPl2_`, markers.monkey, [138.24444493704556, 195], { uniqueDesc: `Upstairs on a shelf.` }),
		new MiscMarker(`VcAW0`, markers.monkey, [129.99532275226102, 339.5], { uniqueDesc: `On top of some wall mounted cases.` }),
		new MiscMarker(`aFKUh`, markers.monkey, [409.2206641248448, 212.25], { uniqueDesc: `Behind the porta-potties, by the gasoline tank.` }),
		new MiscMarker(`VoB7V`, markers.maxisQuestRadio, [206.42256828945733, 323.91253771346766]),
		new MiscMarker(`9njmA`, markers.maxisAmp, [215.19215452397677, 342.01386314625773]),
		new MiscMarker(`ewKJj`, markers.maxisAmp, [171.7939457223806, 300.8642661996666]),
		new MiscMarker(`51Np2`, markers.maxisAmp, [224.07417135124643, 326.3860107539731]),
		new MiscMarker(`rYPHj`, markers.projector, [199.38751401269195, 138.7419183294555]),
		new MiscMarker(`43cui`, markers.redRift, [158.8271253455655, 253.75958752419623]),
	],
	[MapIds.golova]: [
		new MiscMarker(`QkQhl`, markers.monkey, [179.47691966660756, 151], { uniqueDesc: `In the elevated building behind a chain link fence.` }),
		new MiscMarker(`mk0gS`, markers.monkey, [351.4586185493882, 227], { uniqueDesc: `Up in the rafters.` }),
		new MiscMarker(`xsRN7`, markers.monkey, [106.49109327895019, 339.5], { uniqueDesc: `On a pillar at the top of the scaffolding.` }),
		new MiscMarker(`8xhDt`, markers.monkey, [185.48268753325056, 416.75], { uniqueDesc: `On the top shelf.` }),
		new MiscMarker(`hqWBb`, markers.maxisQuestRadio, [363.24480398310953, 314.1474017192487]),
		new MiscMarker(`ejgH2`, markers.maxisAmp, [360.01596128103745, 299.9974734072269]),
		new MiscMarker(`ESg7F`, markers.maxisAmp, [346.24589681631824, 345.3912031598874]),
		new MiscMarker(`UfSJA`, markers.maxisAmp, [386.2265667449167, 326.8728406038857]),
		new MiscMarker(`iMHwr`, markers.projector, [177.0071341819346, 347.625]),
		new MiscMarker(`oI3EF`, markers.redRift, [92.49637125376842, 331.625]),
	],
	[MapIds.sanatorium]: [
		new MiscMarker(`xpCIP`, markers.monkey, [317.4583525447774, 209.5], { uniqueDesc: `In the Admins office on a bookshelf floor 2.` }),
		new MiscMarker(`nOKZv`, markers.monkey, [129.74498802979247, 278.375], { uniqueDesc: `Up in the rafters.` }),
		new MiscMarker(`BDOca`, markers.monkey, [265.106262191878, 348], { uniqueDesc: `Underneath the bridge, on a support pillar.` }),
		new MiscMarker(`bEmlA`, markers.monkey, [194.48468256783116, 67.75], { uniqueDesc: `On the top shelf above the bed.` }),
		new MiscMarker(`4hUUQ`, markers.maxisQuestRadio, [259.0957413648168, 187.83019728788105]),
		new MiscMarker(`PtQYU`, markers.maxisAmp, [246.08093455644018, 197.18064101428752]),
		new MiscMarker(`s96qD`, markers.maxisAmp, [277.228020753186, 221.88350248067215]),
		new MiscMarker(`8Pa9A`, markers.maxisAmp, [269.3306865248022, 163.8223012335942]),
		new MiscMarker(`ZO8MP`, markers.projector, [364.45335165809536, 179.5]),
		new MiscMarker(`SFmVd`, OutbreakEE2Steps.step2Helicopter, [178.2582145549112, 78.59647195866988]),
		new MiscMarker(`g0Vbn`, OutbreakEE2Steps.step3Orb, [128.51585279728454, 269.42304126372875], { uniqueDesc: `Can spawn in 1 of 3 locations, this one is located on the roof` }),
		new MiscMarker(`ecy3g`, OutbreakEE2Steps.step3Orb, [393.4503192055329, 163.5], { uniqueDesc: `Can spawn in 1 of 3 locations, this one is located on the roof` }),
		new MiscMarker(`brKfY`, OutbreakEE2Steps.step3Orb, [264.5128764056229, 197.08519448344717], { uniqueDesc: `Can spawn in 1 of 3 locations, this one is located in the middle of the pool` }),
		new MiscMarker(`8pP_f`, markers.bunny, [124.24775686224359, 132.53094065305984]),
		new MiscMarker(`nslr3`, markers.bunny, [246.10022477665626, 218.71196917701238]),
		new MiscMarker(`nH2On`, markers.bunny, [335.59375, 150.75]),
		new MiscMarker(`_dw2s`, markers.secretArea, [299.70855299802906, 351.714669003909], { uniqueDesc: `Orda boss fight location, the rebuilt rover is required in order to pass through the barrier.` }),
	],
	[MapIds.collateral]: [
		new MiscMarker(`U1mRn`, markers.maxisQuestRadio, [103.80643363061118, 137.17767184651254]),
		new MiscMarker(`RR9UL`, markers.maxisAmp, [123.91123698199438, 182.99474070850823]),
		new MiscMarker(`2XQVR`, markers.maxisAmp, [87.53111663187241, 98.06220410164455]),
		new MiscMarker(`uJGup`, markers.maxisAmp, [153.23943961285346, 139.44430689634171]),
		new MiscMarker(`luWBb`, markers.monkey, [327.65625, 464.46875], { uniqueDesc: `On a windowsill outside.` }),
		new MiscMarker(`xTY5J`, markers.monkey, [145.3085984678254, 330.7769433572229], { uniqueDesc: `Inside some broken pipe.` }),
		new MiscMarker(`WynRr`, markers.monkey, [142.10038624739008, 193.20451003781437], { uniqueDesc: `By the feet of the silos.` }),
		new MiscMarker(`NLT6K`, markers.monkey, [290.6490258001013, 166.6555049819262]),
		new MiscMarker(`T42U3`, markers.projector, [276.52649758435535, 255.64213181187142]),
		new MiscMarker(`XLltd`, markers.redRift, [268.485236370739, 177.6590598896251]),
	],
	[MapIds.armada]: [
		new MiscMarker(`V3lB8`, markers.maxisQuestRadio, [190.56726082261702, 140.05125932484833]),
		new MiscMarker(`QKe5Z`, markers.maxisAmp, [193.87692988626395, 149.98026651578908]),
		new MiscMarker(`3DpDG`, markers.maxisAmp, [187.2575917589701, 179.9414811972243]),
		new MiscMarker(`vbxKd`, markers.maxisAmp, [196.83821273268487, 64.62564329542131]),
		new MiscMarker(`pYrMn`, markers.monkey, [313.2485303432156, 359.03100785491637], { uniqueDesc: `Chilling on the chair.` }),
		new MiscMarker(`8F7tJ`, markers.monkey, [181.15841579988762, 144.79529969011574], { uniqueDesc: `Second Floor, in the corner.` }),
		new MiscMarker(`WIJtD`, markers.monkey, [251.23276211928132, 316.3504914936959], { uniqueDesc: `Between two control boards on the floor.` }),
		new MiscMarker(`qk9uh`, markers.monkey, [228.8863956945445, 244.4522948695135], { uniqueDesc: `Hiding behind the curtain.` }),
		new MiscMarker(`SaBpE`, markers.projector, [322.80314185151997, 353.37968827572143]),
		new MiscMarker(`gLRQZ`, markers.redRift, [281.5872778794272, 99.5338716371389], { uniqueDesc: `Underwater, used to start the main quest.` }),
	],
	/////////////////////Bo6 Items/////////////////////////
	[MapIds.libertyFalls]: [
		new MiscMarker(`GCP5X`, markers.wonderWeapon, [192.74919588520245, 336.28228145349664], { uniqueTitle: `Jet Gun Room`, uniqueDesc: `A room that will burst open with zombies when approached. Contains a workbench and hints on how to build the Wonder Weapon.` }),
		new MiscMarker(`nrdC1`, markers.secretArea, [315.93382666269605, 361.87607283382033], { uniqueTitle: `Radio House`, uniqueDesc: `Doors can be shot open with a mangler canon, either by the player crafting one and shooting it, or getting shot by a mangler by accident.` }),
		new MiscMarker(`6kxsh`, markers.secretArea, [287.28585675673804, 165.51984877300072], { uniqueTitle: `Groundskeepers Shed`, uniqueDesc: `Contains the Handbrake for the Jet Gun quest. Can only be opened by using the shed key. You can find the groundskeeper zombie in the cemetery, kill him before he teleports away, dropping his key.` }),
		new MiscMarker(`dmzoe`, markers.wonderWeaponCollectible, [319.65450152866737, 375.7881614631044], { uniqueTitle: `Wires`, uniqueDesc: `Used in the Jet Gun quest.\nInteract with the black piles of electrical rubbish in order to find wires from one of them.` }),
		new MiscMarker(`fv8Dz`, markers.wonderWeaponCollectible, [290.29374516958853, 167.1612637421355], { uniqueTitle: `Handbrake`, uniqueDesc: `Used in the Jet Gun quest.\nLocked inside the groundskeeper's shed.` }),
		new MiscMarker(`4vmr0`, markers.wonderWeaponCollectible, [128.12063016736064, 253.00589088605057], { uniqueTitle: `Pressure Gauge`, uniqueDesc: `Used in the Jet Gun quest.\nLocked behind a panel that can be shot open, then it can be obtained by overpressuring it using a tap.` }),
		new MiscMarker(`IKvE8`, markers.questCollectible, [285.0994471843135, 241.54550949637775], { uniqueTitle: `Water Valve`, uniqueDesc: `Used with the pressure gauge in the bowling alley to obtain the jet gun part.` }),
		new MiscMarker(`7RCQd`, markers.mainQuest, [209.9971117591179, 111.23062643890167], { uniqueTitle: `S.D.G Generator`, uniqueDesc: `(Simultaneous Dimensional Gateway)\nA device that uses charged aetherium canisters to close the dark aether. Players must charge 2 canisters and return them here to begin the boss battle.` }),
		new MiscMarker(`SvGXG`, markers.questCollectible, [309.71024070860136, 323.1210254565541], { uniqueTitle: `1 of 3 L.T.G Parts`, uniqueDesc: `Stuck in the roof, can only be obtained with the Jet Gun.` }),
		new MiscMarker(`O1Xh5`, markers.questCollectible, [238.9828877298804, 252.11651330106537], { uniqueTitle: `1 of 3 L.T.G Parts`, uniqueDesc: `Inside a room on the second floor, visible when stood on the white truck. Can only be obtained with the Jet Gun.` }),
		new MiscMarker(`fyjJz`, markers.questCollectible, [197.3726160604251, 123.97160585884909], { uniqueTitle: `1 of 3 L.T.G Parts`, uniqueDesc: `Hovers on the left side of the balcony when looking towards the main door. Can only be obtained with the Jet Gun.` }),
		new MiscMarker(`33Wyd`, markers.mainQuest, [273.2269499402426, 362.52314672355453], { uniqueTitle: `Aether Storm`, uniqueDesc: `Used with the L.T.G in the main quest to spawn a HVT.` }),
		new MiscMarker(`VGizY`, markers.mainQuest, [244.75569879194023, 125.37056613599053], { uniqueTitle: `Aether Storm`, uniqueDesc: `Used with the L.T.G in the main quest to spawn a HVT.` }),
		new MiscMarker(`mrMRU`, markers.questCollectible, [157.61254240360353, 214.89424677771137], { uniqueTitle: `1 of 3 Particle Projectors`, uniqueDesc: `Used in the main quest to open the next aetherium canister. Use the equipment provided by the S.D.G to set the scope light to the correct colour for each scope.` }),
		new MiscMarker(`9uYMI`, markers.questCollectible, [283.47680491477615, 218.01350931794695], { uniqueTitle: `1 of 3 Particle Projectors`, uniqueDesc: `Used in the main quest to open the next aetherium canister. Use the equipment provided by the S.D.G to set the scope light to the correct colour for each scope.` }),
		new MiscMarker(`SkNCT`, markers.questCollectible, [233.46875, 328.78125], { uniqueTitle: `1 of 3 Particle Projectors`, uniqueDesc: `Used in the main quest to open the next aetherium canister. Use the equipment provided by the S.D.G to set the scope light to the correct colour for each scope.` }),
		new MiscMarker(`xG8zU`, markers.mainQuest, [219.50905939855957, 413.9982260079434], { uniqueTitle: `Uncharged Aetherium Canister`, uniqueDesc: `Used in the main quest after charging the first canister. Must be retrieved and charged in the same way as the first canister using the L.T.G, HVT and a D.A.G.F trap.` }),
	],
	[MapIds.terminusBiolabs]: [
		new MiscMarker(`HfuK3`, markers.bossFight, [327.2210225067579, 277.0954343324285], { uniqueTitle: `Final Fight`, uniqueDesc: `After defeating Nathan and defusing the bombs.` }),
		new MiscMarker(`hDBzb`, markers.bossFight, [303.5568376364614, 276.72878485193456], { linkedItems: `CjGiW,VkRsN,7G0o1`, uniqueTitle: `Nathan`, uniqueDesc: `After obtaining the hard drive in the main quest, open his cage using 3 numbers found around the map in this order: Interrogation Rooms, Food Hall, Engineering.\nOnce entered you'll need to open the cage all at the same time if playing with multiple players.` }),
		new MiscMarker(`B1eCK`, markers.wonderWeaponStep, [284.7725288507301, 392.2537610808635], { linkedItems: `MFkM_`, uniqueTitle: `Orb #1 - Blue`, uniqueDesc: `Spawns after waiting for the resonator to calibrate at round 9.\n1 of 3, energetic orbs that charge a zombie with energy, the energy packet that is dropped must be carried to the orb.` }),
		new MiscMarker(`H01OH`, markers.wonderWeaponStep, [136.17720324583186, 260.8916789457183], { linkedItems: `B1eCK`, uniqueTitle: `Orb #2 - Green`, uniqueDesc: `2 of 3, energetic orbs that charge a zombie with energy, the energy packet that is dropped must be carried to the orb.` }),
		new MiscMarker(`1z76S`, markers.wonderWeaponCollectible, [397.9117982033145, 201.5820329335165], { linkedItems: `H01OH`, uniqueTitle: `AMP Munition & Orb #3 - Purple`, uniqueDesc: `3 of 3, once finished the AMP munition can be picked straight up off the floor where the orb was.` }),
		new MiscMarker(`kpoPI`, markers.wonderWeaponStep, [223.75, 275.5625], { linkedItems: `MFkM_`, uniqueTitle: `Harmonic Triangulator`, uniqueDesc: `1 of 3 computers that must be activated after plugging the injector into the resonator, the diagram representing the number (X, Y or Z) will appear as a yellow note below the resonator when activated. We recommend the following calculator if you're struggling:`, externalLinks: `hub.tcno.co/games/bo6/terminus/` }),
		new MiscMarker(`CPqC_`, markers.questCollectible, [295.375, 292.75], { linkedItems: `BO6S0TAr2,hDBzb`, uniqueTitle: `Keycard`, uniqueDesc: `Found in the water after defeating Nathan. Used to open the secret door underneath the cargo ship.` }),
		new MiscMarker(`vKqWW`, markers.secretArea, [226.6600102503698, 131.84035663886576], { linkedItems: `CPqC_`, uniqueTitle: `Node Connector Lockdown Room`, uniqueDesc: `Only accessible after obtaining the keycard. Climb the ladder to get inside. Must remain in this room and defeat zombies until the lockdown ends. Lockdown starts when you pick up a Node Connector.` }),
		new MiscMarker(`gJNh7`, markers.questCollectible, [223.42015876022728, 124.8854754400265], { linkedItems: `sAcWz,CzhcI,U14_m`, uniqueTitle: `Node Connector`, uniqueDesc: `1 of 2\nFound inside the cargo ship. Must be taken to the 2 empty sections of the 3 total Node Connector points.` }),
		new MiscMarker(`sAcWz`, markers.mainQuest, [136.10428195026734, 204.40524245364648], { linkedItems: `gJNh7`, uniqueTitle: `Node Connector Section`, uniqueDesc: `1 of 3\nIf empty will need to be repaired with 1 of the 2 Node Connectors.` }),
		new MiscMarker(`CzhcI`, markers.mainQuest, [212.58314921057286, 306.68622849204047], { linkedItems: `gJNh7`, uniqueTitle: `Node Connector Section`, uniqueDesc: `1 of 3\nIf empty will need to be repaired with 1 of the 2 Node Connectors.` }),
		new MiscMarker(`U14_m`, markers.mainQuest, [260.1956545572801, 256.72058738456184], { linkedItems: `gJNh7`, uniqueTitle: `Node Connector Section`, uniqueDesc: `1 of 3\nIf empty will need to be repaired with 1 of the 2 Node Connectors.` }),
		new MiscMarker(`rhX1n`, markers.mainQuest, [153.15786916316517, 174.7691022352682], { linkedItems: `RpokI`, uniqueTitle: `Hacking Buoy`, uniqueDesc: `1 of 3\nCan be hacked after obtaining the Hacking Device from Peck and Strauss. Must be done under a timer and will spawn parasites.` }),
		new MiscMarker(`pEAjE`, markers.mainQuest, [396.16910225823494, 257.21374134292336], { linkedItems: `RpokI`, uniqueTitle: `Hacking Buoy`, uniqueDesc: `1 of 3\nCan be hacked after obtaining the Hacking Device from Peck and Strauss. Must be done under a timer and will spawn parasites.` }),
		new MiscMarker(`5wus2`, markers.mainQuest, [194.92670670317787, 372.92429495818897], { linkedItems: `RpokI`, uniqueTitle: `Hacking Buoy`, uniqueDesc: `1 of 3\nCan be hacked after obtaining the Hacking Device from Peck and Strauss. Must be done under a timer and will spawn parasites.` }),
		new MiscMarker(`EajSJ`, markers.mainQuest, [313.2611643581533, 249.5642753732451], { uniqueTitle: `Bomb Defusal`, uniqueDesc: `1 of 3\nMust be defused directly after the hacking step. You will be timed, use distractions or field upgrades to buy time and defuse. The progress does not reset so keep going until all 3 are done.` }),
		new MiscMarker(`apKMt`, markers.mainQuest, [313.53603670117946, 303.7654809356315], { uniqueTitle: `Bomb Defusal`, uniqueDesc: `1 of 3\nMust be defused directly after the hacking step. You will be timed, use distractions or field upgrades to buy time and defuse. The progress does not reset so keep going until all 3 are done.` }),
		new MiscMarker(`pzVxm`, markers.mainQuest, [328.84375, 277.03125], { uniqueTitle: `Bomb Defusal`, uniqueDesc: `1 of 3\nMust be defused directly after the hacking step. You will be timed, use distractions or field upgrades to buy time and defuse. The progress does not reset so keep going until all 3 are done.` }),
	],
	[MapIds.terminusPrison]: [
		new MiscMarker(`Ou1IZ`, markers.secretArea, [253.5625, 273.53125], { uniqueTitle: `Aanya Tonovsky's Lab`, uniqueDesc: `Can be opened by shooting electrical boxes on a weapon with deadwire equipped, whilst riding the inclined lift up from biolabs.` }),
		new MiscMarker(`qqgUA`, markers.wonderWeaponCollectible, [257.59375, 272.625], { linkedItems: `YPTol`, uniqueTitle: `Briefcase Chip`, uniqueDesc: `An electronic chip used to open the briefcase in the Sea Tower.` }),
		new MiscMarker(`YPTol`, markers.wonderWeaponCollectible, [205.0625, 298.78125], { linkedItems: `MFkM_`, uniqueTitle: `Multiphasic Injector`, uniqueDesc: `A briefcase chained to Aanya's hand. Opens when interacting with it, only after obtaining the chip.` }),
		new MiscMarker(`MFkM_`, markers.wonderWeaponStep, [256.0625, 275.71875], { linkedItems: `kpoPI,LfFld,Mf27c`, uniqueTitle: `Multiphasic Resonator`, uniqueDesc: `A machine that is used to begin the orb step. Plug in the Multiphasic Injector and then activate the 3 Harmonic Triangulators around the map. return when you have activated all 3 and input the code, calculated using the information on the whiteboards. We recommend the following calculator if you're struggling:`, externalLinks: `hub.tcno.co/games/bo6/terminus/` }),
		new MiscMarker(`Mf27c`, markers.wonderWeaponStep, [277.25, 259.375], { linkedItems: `MFkM_`, uniqueTitle: `Harmonic Triangulator`, uniqueDesc: `1 of 3 computers that must be activated after plugging the injector into the resonator, the diagram representing the number (X, Y or Z) will appear as a yellow note below the resonator when activated. We recommend the following calculator if you're struggling:`, externalLinks: `hub.tcno.co/games/bo6/terminus/` }),
		new MiscMarker(`LfFld`, markers.wonderWeaponStep, [273.5625, 287.96875], { linkedItems: `MFkM_`, uniqueTitle: `Harmonic Triangulator`, uniqueDesc: `1 of 3 computers that must be activated after plugging the injector into the resonator, the diagram representing the number (X, Y or Z) will appear as a yellow note below the resonator when activated. We recommend the following calculator if you're struggling:`, externalLinks: `hub.tcno.co/games/bo6/terminus/` }),
		new MiscMarker(`da69a`, markers.questCollectible, DefaultPOIData.nullLoc, { linkedItems: `_lR_D,gI_wA,mnhja,zs2vI,RpokI`, uniqueTitle: `Hard Drive`, uniqueDesc: `Can be found in any of 4 locations. The one you want is already smashed and has blood and a badge below it. Use the Beamsmasher's primary fire on the trap whilst active. Required to progress the main quest and find Nathan.` }),
		new MiscMarker(`RpokI`, markers.mainQuest, [303.9692806052967, 277.8106048830418], { uniqueTitle: `Peck & Strauss`, uniqueDesc: `Can be interacted with to complete the code for the resonator for 5000 essence.\nMust also be interacted with in order to find Nathan after obtaining the Hard Drive.\nFinally, they will provide the Hacking Device after completing the rewiring step.` }),
		new MiscMarker(`CjGiW`, markers.clue, [284.6924857528651, 260.8233832401894], { linkedItems: `hDBzb`, uniqueTitle: `Cell Code #1 - Clock`, uniqueDesc: `The hour hand of the clock.\nA clue for one of the numbers in the 3 digit code for Nathan's cell.` }),
		new MiscMarker(`VkRsN`, markers.clue, [245.1875, 250.1875], { linkedItems: `hDBzb`, uniqueTitle: `Cell Code #2 - Playing Card`, uniqueDesc: `The number on the playing card.\nA clue for one of the numbers in the 3 digit code for Nathan's cell.` }),
		new MiscMarker(`7G0o1`, markers.clue, [251.87483340617834, 305.9176019845688], { linkedItems: `hDBzb`, uniqueTitle: `Cell Code #3 - Injury Sign`, uniqueDesc: `The number of days since last injury sign\n.A clue for one of the numbers in the 3 digit code for Nathan's cell.` }),
	],
	[MapIds.citadelle]: [
		new MiscMarker(`7aKbb`, markers.secretArea, [420.6982899821542, 209.87796290629709], { uniqueTitle: `Alchemical Lab`, uniqueDesc: `Walls will open up and reveal this room after activating the PaP machine.` }),
		new MiscMarker(`Le2z8`, markers.mainQuest, [379.5781514302129, 414.1441537743096], { uniqueTitle: `Gabriel Krafft's Cell`, uniqueDesc: `Can be interacted with after activating PaP to begin the main quest and unlock some previously disabled egg steps.` }),
		new MiscMarker(`TB1Ey`, markers.questCollectible, [368.2433698321437, 186.02154976715738], { linkedItems: `Ux1rW`, uniqueTitle: `Torn Paper #1`, uniqueDesc: `This page tells you the first Point of Power trap that needs to be completed, make a note of it if you'd like to avoid the wall step. Can be found around the hallway area.` }),
		new MiscMarker(`lgO4Q`, markers.questCollectible, [379.4773532994321, 158.7626192950606], { linkedItems: `Ux1rW`, uniqueTitle: `Torn Paper #2`, uniqueDesc: `This page tells you the second Point of Power trap that needs to be completed, make a note of it if you'd like to avoid the wall step. Can be found around the beds area.` }),
		new MiscMarker(`SLY7x`, markers.questCollectible, [408.2231345245523, 172.9703042683959], { linkedItems: `Ux1rW`, uniqueTitle: `Torn Paper #3`, uniqueDesc: `This page tells you the third Point of Power trap that needs to be completed, make a note of it if you'd like to avoid the wall step. Can be found around the sofa and TV area.` }),
		new MiscMarker(`ELKta`, markers.questCollectible, [418.96150107416616, 181.56099750808698], { linkedItems: `Ux1rW`, uniqueTitle: `Torn Paper #4`, uniqueDesc: `This page tells you the final Point of Power trap that needs to be completed, make a note of it if you'd like to avoid the wall step. Can be found around the hallway area by the alchemical lab.` }),
		new MiscMarker(`OZOnd`, markers.questCollectible, DefaultPOIData.nullLoc, { uniqueTitle: `Stamp`, uniqueDesc: `Used to unlock any of the 4 bastard swords from their pedestals.`, linkedItems: `QfxS5,KGyrV,42s5g,9C1DV`, }),
		new MiscMarker(`QfxS5`, markers.bastardSword, [413.3523471673312, 267.3763282749791], { uniqueTitle: `The Lion`, uniqueDesc: `To upgrade you will need to kill a glowing parasite (4 of them spawn upon picking up the sword for the first time), bring the glow that will appear on your sword, and hit the lion door, located in the town with it.`, linkedItems: `SKx8c`, }),
		new MiscMarker(`KGyrV`, markers.bastardSword, [403.27965861059096, 267.3763282749791], { uniqueTitle: `The Dragon`, uniqueDesc: `To upgrade you will need to take the sword to the dragon statue at the top of the stairs by the castle entrance.`, linkedItems: `8QlMF`, }),
		new MiscMarker(`42s5g`, markers.bastardSword, [413.3523471673312, 241.10285219882513], { uniqueTitle: `The Raven`, uniqueDesc: `To upgrade you will need to find a statue in the Alchemical Lab with an animal part. Then head to the pub basement.`, linkedItems: `4cszs`, externalLinks: `hub.tcno.co/games/bo6/raven/` }),
		new MiscMarker(`9C1DV`, markers.bastardSword, [403.27965861059096, 241.10285219882513], { uniqueTitle: `The Stag`, uniqueDesc: `To upgrade you will need to find all 3 lighting rods, place them in the container and complete a lockdown.`, linkedItems: `Rlskg`, }),
		new MiscMarker(`SKx8c`, markers.bastardSwordUpgrade, [169.91139256371156, 321.3595794743913], { uniqueTitle: `Upgrade - The Lion`, uniqueDesc: `A door with a lion emblem. Once your sword is glowing from killing a glowing parasite, hit this door with it to light up 1 of 4 sybmols. After doing all 4, interact with the door to start a lockdown. Once inside place the sword in the pedestal and shoot 4 hovering symbols that appear in the order they light up on the pedestal.`, linkedItems: `QfxS5`, }),
		new MiscMarker(`8QlMF`, markers.bastardSwordUpgrade, [359.6393618745068, 219.52537395857806], { uniqueTitle: `Upgrade - The Dragon`, uniqueDesc: `After placing the sword in the statue, bring 3 flames from brasiers around the map, back to the statue. You can melee zombies to regain health`, linkedItems: `2iUPs,mpOkM,DPIJA`, }),
		new MiscMarker(`4cszs`, markers.bastardSwordUpgrade, [131.21974809406814, 220.4834266507181], { uniqueTitle: `Upgrade - The Raven`, uniqueDesc: `Find the circular puzzle. Place the antiquity that you picked up, then place the sword. Figure out the zodiac symbol and Aristotelian element that represents the antiquity, then align the 2 correct sybmbols. After doing this you will need to guide orbs into portals 3 times.`, linkedItems: `2ksQs`, externalLinks: `hub.tcno.co/games/bo6/raven/` }),
		new MiscMarker(`Rlskg`, markers.bastardSwordUpgrade, [256.47471713594445, 157.6967812528588], { uniqueTitle: `Upgrade - The Stag`, uniqueDesc: `After finding .`, linkedItems: `JqsLU,hh3Pv,wb60Z`, }),
		new MiscMarker(`2iUPs`, markers.bastardSwordUpgradeCollectible, [94.45178460028421, 169.41059021730663], { uniqueTitle: `Brasier #1`, uniqueDesc: `1 of 3\nWill be lit only after placing the sword in the statue. When interacted with will enpower the user and they must get back to the statue and interact with it.`, linkedItems: `8QlMF`, }),
		new MiscMarker(`mpOkM`, markers.bastardSwordUpgradeCollectible, [67.50799835278339, 248.53460103762404], { uniqueTitle: `Brasier #2`, uniqueDesc: `1 of 3\nWill be lit only after placing the sword in the statue. When interacted with will enpower the user and they must get back to the statue and interact with it.`, linkedItems: `8QlMF`, }),
		new MiscMarker(`DPIJA`, markers.bastardSwordUpgradeCollectible, [224.79227752072111, 261.33895616366], { uniqueTitle: `Brasier #3`, uniqueDesc: `1 of 3\nWill be lit only after placing the sword in the statue. When interacted with will enpower the user and they must get back to the statue and interact with it.`, linkedItems: `8QlMF`, }),
		new MiscMarker(`2ksQs`, markers.bastardSwordUpgradeCollectible, [414.92783786723436, 210.1867410559687], { uniqueTitle: `Antiquity`, uniqueDesc: `Used for the Raven Sword upgrade.\nFound in the Alchemical Lab on any of the shelves around the room. Will be random and one of several variants. Once obtained, must be placed in the circular puzzle in the pub to activate it.`, linkedItems: `4cszs`, externalLinks: `hub.tcno.co/games/bo6/raven/` }),
		new MiscMarker(`JqsLU`, markers.bastardSwordUpgradeCollectible, [189.7252351749281, 326.5722112559408], { uniqueTitle: `Lightning Rod #1`, uniqueDesc: `1 of 3\nCan be collected in any order`, linkedItems: `Rlskg`, }),
		new MiscMarker(`hh3Pv`, markers.bastardSwordUpgradeCollectible, [175.5730969793589, 148.32972850592168], { uniqueTitle: `Lightning Rod #2`, uniqueDesc: `1 of 3\nCan be collected in any order`, linkedItems: `Rlskg`, }),
		new MiscMarker(`wb60Z`, markers.bastardSwordUpgradeCollectible, [347.1359786813524, 424.00529977158135], { uniqueTitle: `Lightning Rod #3`, uniqueDesc: `1 of 3\nFound in the dungeon, drops from an armoured zombie. Can be collected in any order. `, linkedItems: `Rlskg`, }),
		new MiscMarker(`cC4KM`, markers.ritualCollectible, [431.81941352000524, 222.23781206063748], { uniqueTitle: `Ritual Item - The Lion`, uniqueDesc: `Paladin's Brooch\nObtained by shooting each red crystal light along the walls, starting with the one above the fireplace in the dining room. Aim the light beam at the next one, by shooting it from the left or right side of each crystal.`, linkedItems: `Rz6X0`, }),
		new MiscMarker(`1kPgF`, markers.ritualCollectible, DefaultPOIData.nullLoc, { uniqueTitle: `Ritual Item - The Dragon`, uniqueDesc: `Ankh Charm\nYou must ignite 3 wood bundles and kill a Doppelghast to drop this item. It has no fixed location.`, linkedItems: `bdRP2,ZT7wi,Jw3mF`, }),
		new MiscMarker(`bdRP2`, markers.ritualStep, [272.91008968927326, 157.77883305787276], { uniqueTitle: `Ritual Item Step - The Dragon`, uniqueDesc: `Wood Bundle\nNeeds to be ignited using any source of fire e.g. molotov, fire sword, incendiary. Once all 3 are lit, a Doppelghast will spawn, kill it to obtain the Ankh Charm`, linkedItems: `KINbg`, }),
		new MiscMarker(`ZT7wi`, markers.ritualStep, [286.7132597387412, 218.4766914905465], { uniqueTitle: `Ritual Item Step - The Dragon`, uniqueDesc: `Wood Bundle\nNeeds to be ignited using any source of fire e.g. molotov, fire sword, incendiary. Once all 3 are lit, a Doppelghast will spawn, kill it to obtain the Ankh Charm`, linkedItems: `KINbg`, }),
		new MiscMarker(`Jw3mF`, markers.ritualStep, [274.7477963919653, 276.61682902048204], { uniqueTitle: `Ritual Item Step - The Dragon`, uniqueDesc: `Wood Bundle\nNeeds to be ignited using any source of fire e.g. molotov, fire sword, incendiary. Once all 3 are lit, a Doppelghast will spawn, kill it to obtain the Ankh Charm`, linkedItems: `KINbg`, }),
		new MiscMarker(`RMXsK`, markers.ritualCollectible, [154.2681238388354, 234.19869373972807], { uniqueTitle: `Ritual Item - The Raven`, uniqueDesc: `Raven Talon\nObtained by taking the sewer exit from the castle and shooting the Raven in the tunnel. Once out of the pipe, shoot the raven in the sky for it to drop it's talon.`, linkedItems: `IZOQg,RXF7n`, }),
		new MiscMarker(`SfjDB`, markers.ritualCollectible, [192.21186406484745, 165.52074780607552], { uniqueTitle: `Ritual Item Step - The Stag`, uniqueDesc: `Otto's Horseshoe\nShoot it from above the doorframe in the barn by deadshot.`, linkedItems: `Rlskg`, }),
		new MiscMarker(`7ERDa`, markers.ritualCollectible, [192.21186406484745, 165.52074780607552], { uniqueTitle: `Ritual Item - The Stag`, uniqueDesc: `Pegasus's Horseshoe\nAfter obtaining Otto's Horseshoe, fire yourself from the cannon to spawn, then look in the air for a stormcloud. The horseshoe will appear beneath it.`, linkedItems: `8A54Q,3wP8a`, }),
		new MiscMarker(`Rz6X0`, markers.ritualStep, [389.5287826348198, 242.4112235081103], { uniqueTitle: `Ritual - The Lion`, uniqueDesc: `Place the ritual item, get kills to charge it. When finished an incantation box will appear, use the upgraded sword of the correct type to open the box.`, linkedItems: `cC4KM`, }),
		new MiscMarker(`KINbg`, markers.ritualStep, [232.89430147053977, 248.2862956684153], { uniqueTitle: `Ritual - The Dragon`, uniqueDesc: `Place the ritual item, get kills to charge it. When finished an incantation box will appear, use the upgraded sword of the correct type to open the box.`, linkedItems: `8QlMF`, }),
		new MiscMarker(`RXF7n`, markers.ritualStep, [356.97616797402475, 309.9245921952382], { uniqueTitle: `Ritual - The Raven`, uniqueDesc: `Place the ritual item, get kills to charge it. When finished an incantation box will appear, use the upgraded sword of the correct type to open the box.`, linkedItems: `RMXsK`, }),
		new MiscMarker(`3wP8a`, markers.ritualStep, [84.93142119636467, 189.07043611497446], { uniqueTitle: `Ritual - The Stag`, uniqueDesc: `Place the ritual item, get kills to charge it. When finished an incantation box will appear, use the upgraded sword of the correct type to open the box.`, linkedItems: `SfjDB`, }),
		new MiscMarker(`UV7WP`, markers.pointOfPower, [323.55385236404203, 376.13837088011826], { linkedItems: `VunDA` }),
		new MiscMarker(`RSzpo`, markers.pointOfPower, [354.7299089317141, 444.3757334270622], { linkedItems: `VunDA` }),
		new MiscMarker(`QPh5Z`, markers.pointOfPower, [398.0672220860039, 170.86692346643366], { linkedItems: `VunDA` }),
		new MiscMarker(`BwIRp`, markers.pointOfPower, [249.81997914264485, 219.42950745312723], { linkedItems: `VunDA` }),
		new MiscMarker(`q48DR`, markers.pointOfPower, [192.49981801841787, 132.5426584987053], { linkedItems: `VunDA` }),
		new MiscMarker(`BhTte`, markers.pointOfPower, [156.40214500790114, 294.00889124478766], { linkedItems: `VunDA` }),
		new MiscMarker(`Ux1rW`, markers.clue, [311.3035046967362, 309.74503909124564], { linkedItems: `TB1Ey,lgO4Q,SLY7x,ELKta`, uniqueTitle: `Symbol Wall`, uniqueDesc: `When punched with Melee Macchiato it reveals a wall of symbols that must be shot in the corresponding order to symbols shown on the bottles in the pub that appear after talking to Kraft. After the sequence is complete, it reveals a book to the left that allows you to place the torn pages found in the common room.`, }),
		new MiscMarker(`VunDA`, markers.secretArea, [418.33998040078745, 130.4440763960666], { linkedItems: `6UGOZ`, uniqueTitle: `Secret Study`, uniqueDesc: `The door is revealed after activating and completing all point of power traps, in the correct order as shown in the book next to the symbol wall.\nThe wall can only be opened after obtaining the Mystic Orb`, }),
		new MiscMarker(`6UGOZ`, markers.questCollectible, [408.34375, 254.53125], { linkedItems: `rt2J9,i5G3V,Rx6v2,JKqce`, img: `BGY4B3N`, uniqueTitle: `Mystic Orb`, uniqueDesc: `Is required to open the Secret Study. To spawn it, in the order as noted on the Secret Study Door (Clockwise from the top), perform a weapon inspect with the matching sword in front of the matching statue, then activate the appropriate incantation on the same statue. When done correctly the statue will light up yellow. After the 4th statue is done the Orb will spawn.` },),
		new MiscMarker(`rt2J9`, markers.mainQuest, [389.2594519845276, 253.40086432902942], { linkedItems: `6UGOZ`, uniqueTitle: `Orb Pedestal - The Lion`, uniqueDesc: `Requires the Orb to begin. Starts a timed event to complete, run to each large green orb as fast as possible to charge the orb, no killing is necessary, when completed correctly the orb will have a coloured effect flying around it. They can be completed in any order, must be picked up to begin another.` },),
		new MiscMarker(`i5G3V`, markers.mainQuest, [255.1987428990766, 202.40572051308442], { linkedItems: `6UGOZ`, uniqueTitle: `Orb Pedestal - The Dragon`, uniqueDesc: `Requires the Orb to begin. Starts a timed event to complete, kill zombies with any fire damage including the alt fire of the Dragon sword to charge the orb, when completed correctly the orb will have a coloured effect flying around it. They can be completed in any order, must be picked up to begin another.` },),
		new MiscMarker(`Rx6v2`, markers.mainQuest, [348.92905823801664, 306.6692377640179], { linkedItems: `6UGOZ`, uniqueTitle: `Orb Pedestal - The Raven`, uniqueDesc: `Requires the Orb to begin. Starts a timed event to complete, kill zombies with shadow rift effects, including Kazimir devices and the alt fire of the Raven sword to charge the orb, when completed correctly the orb will have a coloured effect flying around it. They can be completed in any order, must be picked up to begin another.` },),
		new MiscMarker(`JKqce`, markers.mainQuest, [90.29487190696896, 187.47728224104117], { linkedItems: `6UGOZ`, uniqueTitle: `Orb Pedestal - The Stag`, uniqueDesc: `Requires the Orb to begin. Starts a timed event to complete, kill zombies with any electrical effect, including the alt fire of the Stag sword to charge the orb, when completed correctly the orb will have a coloured effect flying around it. They can be completed in any order, must be picked up to begin another.` },),
		new MiscMarker(`mPlz7`, markers.questCollectible, [418.39903763765665, 122.84038315591837], { linkedItems: `fKytn`, img: `ZkW20YW`, uniqueTitle: `Guardian Key`, uniqueDesc: `Found after opening the Study door with the charged orb by performing all 4 orb rituals. This recording can be activated and collected in order to begin the bossfight, back at spawn.` },),
		new MiscMarker(`fKytn`, markers.bossFight, [93.99040847306705, 220.07244946876693], { linkedItems: `mPlz7` },),
	],
	[MapIds.tomb]: [
		new MiscMarker(`r6Fa8`, markers.questCollectible, [420.55463697027795, 173.13252270483], { uniqueTitle: `Tombs Mural`, uniqueDesc: `First obtain the monocle from the first shock mimic. Then shoot aetheric lanterns (and wait for them to respawn) until one is opposite the mural on the left side. Then shoot the lit symbols in order (roman numerals) to trigger a lockdown. The reward is 1 of 2 ice staff parts.`, stepNumber: 1 }),
		new MiscMarker(`ToSQg`, markers.questCollectible, [325.17435628016256, 174.23427512204256], { uniqueTitle: `Caves Mural`, uniqueDesc: `First obtain the monocle from the first shock mimic. Then shoot aetheric lanterns (and wait for them to respawn) until one is opposite the mural. Then shoot the lit symbols in order (roman numerals) to trigger a lockdown. The reward is 1 of 2 ice staff parts.`, stepNumber: 1 }),
		new MiscMarker(`_pOlx`, markers.mainQuest, [186.83734382308813, 354.6147706924116], { uniqueTitle: `Nexus Altar`, uniqueDesc: `Take the ice staff parts here and interact with it to trigger a defend event. Stop the staff being destroyed and you'll earn the base ice staff.`, stepNumber: 2 }),
		new MiscMarker(`nTUaW`, markers.mainQuest, [172.99231014236906, 370.83612752533963], { uniqueTitle: `Floating Rocks`, uniqueDesc: `Shoot 3 aetheric lanterns within 10 seconds of eachother, with the ice staff, then enter the dark aether and look up to see 3 rocks with symbols on them. Shoot them with the staff to lower them, make a note of the symbols.`, stepNumber: 3 }),
		new MiscMarker(`uYOYi`, markers.mainQuest, [171.7013227532469, 345.0163797428965], { uniqueTitle: `Purple Orb`, uniqueDesc: `After the floating rocks are lowered, one of the doors will close off. Go to the other side of the door and shoot the 3 symbols that were on the rocks. If you do it correctly the door will reopen and the 3 symbols will glow on the floor of the door. After entering you'll be dropped on top of the floating rock with the purple orb. Interact with it to trigger a moving soul box, move it by killing nearby the orb, when it ends interact with the alter and the ice staff will be upgraded.`, stepNumber: 4 }),

		new MiscMarker(`lCTn_`, markers.crystalStep, [146.84566686502382, 374.5832845750799], { uniqueTitle: `Vermin Crystal`, stepNumber: 5 }),
		new MiscMarker(`diD9k`, markers.aetherCrystalPodium, [354.33351467626574, 45.504981560781104], { uniqueTitle: `Vermin Podium`, uniqueDesc: `Located in Dig Site.`, stepNumber: 6 }),

		new MiscMarker(`e4htw`, markers.crystalStep, [144.37034150436426, 331.0478857343452], { uniqueTitle: `Parasite Crystal`, stepNumber: 7 }),
		new MiscMarker(`CPBgu`, markers.aetherCrystalPodium, [447.5815793619323, 246.3399112048164], { uniqueTitle: `Parasite Podium`, uniqueDesc: `Purple energy will infect the parasites. Kill them and bring the energy balls back to the podium to charge it.`, stepNumber: 8 }),

		new MiscMarker(`mMyTR`, markers.crystalStep, [202.95409409968445, 338.9471435474322], { uniqueTitle: `Doppelghast Crystal`, stepNumber: 9 }),
		new MiscMarker(`pno4V`, markers.aetherCrystalPodium, [292.38929209419456, 289.42554184227856], { uniqueTitle: `Doppelghast Podium`, uniqueDesc: `Spawns lasers and purple explosions that you should avoid.`, stepNumber: 10 }),

		new MiscMarker(`cOvgh`, markers.crystalStep, [200.192813907852, 373.4631459453377], { uniqueTitle: `Amalgam Crystal`, stepNumber: 11 }),
		new MiscMarker(`oM3Br`, markers.aetherCrystalPodium, [359.5270605963886, 433.10297767778144], { uniqueTitle: `Amalgam Podium`, uniqueDesc: `Spawns a single elite amalgam, when he has a bubble around him you should kill the tethered zombie first before you can continue dealing damage.`, stepNumber: 12 }),

		new MiscMarker(`JdMge`, markers.bossFight, [246.22458823737037, 437.5389143191502], { uniqueDesc: `The bridge will open up after the last step. Cross it and interact with the artifact to start the fight.`, stepNumber: 13 }),
	]
};